import clsx from "clsx";
type DividerProps = {
  readonly isLight?: boolean;
  readonly isBold?: boolean;
  readonly orientation?: "horizontal" | "vertical";
  readonly className?: string;
};

// Partly inspired by: https://mui.com/material-ui/react-divider/
export function Divider({
  className,
  isBold = false,
  isLight = false,
  orientation = "vertical"
}: DividerProps) {
  function verticalClasses() {
    if (orientation !== "vertical") {
      return;
    }
    return clsx(isBold ? "w-[--theme-grid-lg]" : "w-px", "-right-2 top-0 hidden h-full lg:block");
  }
  function horizontalClasses() {
    if (orientation !== "horizontal") {
      return;
    }
    return clsx(isBold ? "h-[--theme-grid-lg]" : "h-[--theme-grid-xs]", "inset-x-0 bottom-0 w-full");
  }
  return <hr className={clsx(className, verticalClasses(), horizontalClasses(), isLight ? "bg-white" : "bg-grid-secondary", "absolute !m-0 border-0")} data-sentry-component="Divider" data-sentry-source-file="Divider.component.tsx" />;
}