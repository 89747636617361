import { Translation } from "../Translation/Translation.component";
import { clsx } from "clsx";
type RequiresSubscriptionLabelProps = {
  readonly className?: string;
};
export function RequiresSubscriptionLabel({
  className
}: RequiresSubscriptionLabelProps) {
  return <p className={clsx(className, "select-none whitespace-nowrap text-xs font-theme-bold uppercase")} data-sentry-component="RequiresSubscriptionLabel" data-sentry-source-file="RequiresSubscriptionLabel.component.tsx">
      <Translation model={{
      da: "For abonnenter",
      de: "Für Abonnenten",
      en: "For subscribers",
      no: "For abonnenter",
      sv: "För prenumeranter"
    }} data-sentry-element="Translation" data-sentry-source-file="RequiresSubscriptionLabel.component.tsx" />
    </p>;
}